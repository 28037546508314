import { ConfigProvider } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import TopBar from './components/navbar/topbar';
import Navbar from './components/navbar';
// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { KEY_firebaseConfig } from './utils/AppConstants';

// Initialize Firebase
const app = initializeApp(KEY_firebaseConfig);
export const analytics = getAnalytics(app);

function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: 'blue',
            borderRadius: 2,

            // Alias Token
            colorBgContainer: 'grey',
          },
          components: {
            Menu: {
              subMenuItemBg: "green"
            },
            Drawer: {
              colorBgTextActive: "green"
            }
          },
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={3000}
          // hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />

        {/* <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route
          path="/generic-component"
          element={
            <GenericComponent />
          }
        />
      </Routes> */}
        <Navbar />
        {/* <TopBar /> */}
      </ConfigProvider>
    </>
  );
}

export default App;
