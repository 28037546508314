import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Breadcrumb, Drawer, Flex, Layout, Menu, Tabs, theme } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Form, Nav, NavDropdown } from 'react-bootstrap';
import { useState } from 'react';
import { logCustomEvent } from '../../../utils/AppExtenstions';

const { Header, Footer, Sider, Content } = Layout;

const items = new Array(5).fill(null).map((_, index) => ({
    key: String(index + 1),
    label: `INTRODUCTION ${index + 1}`,
}));

const tabItems = [
    {
        key: '1',
        label: 'Tab 1',
        children: <div
            style={{
                padding: 24,
                minHeight: 380,
                background: "green",
                borderRadius: "10px",
            }}
        >
        </div>,
    },
    {
        key: '2',
        label: 'Tab 2',
        children: 'Content of Tab Pane 2',
    },
    {
        key: '3',
        label: 'Tab 3',
        children: 'Content of Tab Pane 3',
    },
];

const TopBar = () => {
    // logCustomEvent()
    const [open, setOpen] = useState(true);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <>

            <Layout className='text-center'>
                <Header
                    style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        background: "yellow",
                    }}
                >
                    <div>LOGO
                        <Button type="primary" onClick={showDrawer}>
                            Open
                        </Button>
                    </div>

                    {/* <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        items={items}
                        style={{
                            flex: 1,
                            minWidth: 0,
                            background: "yellow",
                        }}
                        expandIcon={<MenuOutlined />}
                    /> */}
                </Header>
                <Drawer
                    title="Basic Drawer"
                    onClose={onClose}
                    // width="70%"
                    open={open}
                    style={{ background: "#f8f9fa", }}>

                    {
                        items?.map((prop, index) => {
                            return (
                                <div key={`drawer-button-${index}`} className='drawer-btn'>{prop?.key}</div>
                            )
                        })
                    }
                </Drawer>
                <Content
                    style={{
                        padding: '0 10px',
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                        }}
                    >
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>List</Breadcrumb.Item>
                        <Breadcrumb.Item>App</Breadcrumb.Item>
                    </Breadcrumb>
                    <Tabs type='card' items={tabItems} />
                    <div
                        style={{
                            padding: 24,
                            minHeight: 380,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                    </div>

                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Ant Design ©{new Date().getFullYear()} Created by Ant UED
                </Footer>
            </Layout>
        </>
    );
}

export default TopBar;