export const KEY_DEVELOPER = "I am student of MIT Manipal and I possess all the necessary ability and expertise required in this field to be called as IT skilled person. I have innate tendency of being inclined towards this field of technology and I profoundly cherish this tag to carry on my shoulders. \n\nIn this line I have developed this app in my personal capacity and I hope this will serve a good purpose as inclusive platform. You can send me your suggestion(s) regarding the SOIBUGH-ONLINE App and if found appropriate they will be fondly entertained.You can contact me on below mentioned social network addresses."
export const KEY_ABOUT = "I take this moment to pay my gratitude towards Mehraj ud Din Bhat & Syed Suheel Bukhari both PG in History for their kind cooperation by providing substantial material related to Soibugh . I am overwhelmed by this nice gesture of yours . \n\nIn the end I also thank Arhan Waheed and Faheem Aasim for their unwavering support & encouragement. Even though I tried my level best to compact the available information in best possible way which seems infact too little infront of our grand reputation among our periphery. \n\nSo if anyone desires to make a mark in this app by contributing whatever little information they posses, can either hit the below button or contact developer directly by visiting the developer page."
export const KEY_FACEBOOK_ACCESS_TOKEN = "EAAOTXNcLZAx4BOzzUIRG8eYHk58aIFZAMzFfSUTKiAsZCXe4drU2j51ucVLoGYdkUzNJVs6xTXdvJKf3RFuM2WFtOKNaGJSgJmphkUp2eVx7MZBqhG4w5zAHR39Hdj2UUIrcNEUgCiiZBItMcI5vV0iFGeg6P2UcNKlaYp0rzmHPuxZBgWefYzAJ0trCYZB1QAZD"
export const KEY_FACEBOOK_URL = "https://graph.facebook.com/v20.0/125588890842126/feed?fields=message,full_picture,created_time&limit=12"
export const KEY_FACEBOOK_ACTIONS = "https://graph.facebook.com/v20.0/125588890842126/feed?fields=actions&limit=12"
export const KEY_firebaseConfig = {
    apiKey: "AIzaSyCZivUOtIFxVIB-yERdveleJWP-gI-4Wiw",
    authDomain: "soibugh-web.firebaseapp.com",
    projectId: "soibugh-web",
    storageBucket: "soibugh-web.appspot.com",
    messagingSenderId: "521886985628",
    appId: "1:521886985628:web:e876cd30b84b7299b0ac59",
    measurementId: "G-NGZ0BKMD5P"
};


